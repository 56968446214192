<template>
	<div class="flex-1">
		<page-header
			:menu="menu"
		/>
		<invite-members
			:organization="organization"
			:members="orgMembers"
			:plan="plan"
			:stripe-publishable-key="stripePublishableKey"
			:next-bill-date="nextBillDate"
			:show-slideover="showInviteMember"
			@interface="showInviteMember = $event"
		/>
		<update-payment-method
			:org-slug="organization.slug"
			:show-slideover="showUpdatePaymentMethod"
			@interface="showUpdatePaymentMethod = $event"
			:stripe-publishable-key="stripePublishableKey"
		/>
		<flash-messages
			v-if="notification.show"
			:type="notification.type"
			:title="notification.title"
			:message="notification.message"
		/>
		<div class="tw-bg-white tw-px-4 tw-py-5 sm:tw-p-6">
			<div class="md:tw-grid md:tw-grid-cols-3 md:tw-gap-6">
				<div class="md:tw-col-span-1">
					<h3 class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
						Overview
					</h3>
					<p class="tw-mt-1 tw-text-sm tw-text-gray-500">
						The organization billing is based on the number of members that belong to the organization.
					</p>
				</div>
				<div class="tw-mt-5 md:tw-mt-0 md:tw-col-span-2 tw-shadow tw-bg-gray-100 tw-p-5 tw-rounded-lg">
					<dl class="tw-divide-y tw-divide-gray-200">
						<div class="tw-py-4 sm:tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
							<dt class="tw-text-sm tw-font-medium tw-text-gray-700">
								Name
							</dt>
							<dd class="tw-mt-1 tw-text-sm sm:tw-mt-0 sm:tw-col-span-2">
								{{ organization.name }}
								<svg
									v-if="organization.isComplimentary"
									class="tw-w-4 tw-h-4 tw-inline-block tw-text-yellow-400"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									v-tooltip="'Complimentary Organization'"
								>
									<path
										stroke-linecap="round"
										stroke-linejoin="round"
										stroke-width="2"
										d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7"
									/>

								</svg>
							</dd>
						</div>
						<div class="tw-py-4 sm:tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
							<dt class="tw-text-sm tw-font-medium tw-text-gray-700">
								Owner
							</dt>
							<dd class="tw-mt-1 tw-text-sm sm:tw-mt-0 sm:tw-col-span-2">
								<a
									:href="`/@${organization.owner.user.username}`"
									class="tw-block hover:tw-bg-gray-100 hover:tw-text-gray-900"
								>
									<Avatar
										:email="organization.owner.user.email"
										:px="40"
										:size="5"
										custom-class="tw-inline-block"
									/>
									{{ organization.owner.user.fullName }}
								</a>
							</dd>
						</div>
						<div class="tw-py-4 sm:tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
							<dt class="tw-text-sm tw-font-medium tw-text-gray-700">
								Teams
							</dt>
							<dd class="tw-mt-1 tw-text-sm sm:tw-mt-0 sm:tw-col-span-2">
								1
							</dd>
						</div>
						<div class="tw-py-4 sm:tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
							<dt class="tw-text-sm tw-font-medium tw-text-gray-700">
								Members
							</dt>
							<dd class="tw-mt-1 tw-text-sm sm:tw-mt-0 sm:tw-col-span-2">
								{{ organization.members.length }}
							</dd>
						</div>
						<div class="tw-py-4 sm:tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
							<dt class="tw-text-sm tw-font-medium tw-text-gray-700">
								Amount
							</dt>
							<dd class="tw-mt-1 tw-text-sm sm:tw-mt-0 sm:tw-col-span-2">
								${{ currentAmount }}
							</dd>
						</div>
						<div class="tw-py-4 sm:tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4">
							<dt class="tw-text-sm tw-font-medium tw-text-gray-700">
								Next Bill Date
							</dt>
							<dd class="tw-mt-1 tw-text-sm sm:tw-mt-0 sm:tw-col-span-2">
								<span v-if="organization.isComplimentary">
									N/A <span class="tw-italic tw-text-xs">(Complimentary organization)</span>
								</span>
								<span v-else>
									{{ !organization.isComplimentary && organization.members.length > 1 ? nextBillDate : "N/A" }}
								</span>
							</dd>
						</div>
						<div
							v-if="!isEmpty(cardDetails)"
							class="tw-py-4 sm:tw-py-5 sm:tw-grid sm:tw-grid-cols-3 sm:tw-gap-4 sm:tw-px-4"
						>
							<dt class="tw-text-sm tw-font-medium tw-text-gray-700">
								Credit Card On File
							</dt>
							<dd class="tw-mt-1 tw-flex tw-text-sm sm:tw-mt-0 sm:tw-col-span-2">
								<span class="tw-flex-grow">
									****-****-****-{{ cardDetails.card.last4 }} ({{ cardDetails.card.brand | capitalize }})
									<br>
									<span>
										Exp. Date {{ cardDetails.card.exp_month }}/{{ cardDetails.card.exp_year }}
									</span>
								</span>
								<span
									class="tw-ml-4 tw-flex-shrink-0 tw-cursor-pointer tw-text-blue-600 hover:tw-text-blue-500"
									@click="showUpdatePaymentMethod = true"
								>
									Update
								</span>
							</dd>
						</div>
					</dl>
				</div>
			</div>

			<div v-if="canManageBilling">
				<!-- Divider -->
				<divider />

				<!-- Manage Members Section -->
				<div
					class="md:tw-grid md:tw-grid-cols-3 md:tw-gap-6"
				>
					<div class="md:tw-col-span-1">
						<h3 class="tw-text-lg tw-font-medium tw-leading-6 tw-text-gray-900">
							Manage Members
						</h3>
						<p class="tw-mt-1 tw-text-sm tw-text-gray-500">
							You can manage your organization members in this section. Send invites to join the organization and remove members from it.
						</p>

						<p class="tw-font-medium tw-text-md tw-text-gray-900 tw-mt-3">
							Roles
						</p>
						<ul>
							<li class="tw-mt-1 tw-text-sm tw-text-gray-500">
								<span class="tw-font-bold">Super Admin:</span>
								Controls billing & adding and removing people in the org.
							</li>
							<li class="tw-mt-1 tw-text-sm tw-text-gray-500">
								<span class="tw-font-bold">Admin:</span>
								Manages teams & package access.
							</li>
							<li class="tw-mt-1 tw-text-sm tw-text-gray-500">
								<span class="tw-font-bold">Member:</span>
								Works on packages they are given access to.
							</li>
						</ul>
					</div>
					<div class="tw-mt-5 md:tw-mt-0 md:tw-col-span-2 tw-shadow tw-bg-gray-100 tw-p-5 tw-rounded-lg tw-text-gray-500">
						<div>
							<div class="tw-flex">
								<label
									for="first_name"
									class="tw-w-full tw-text-sm tw-font-medium tw-text-gray-700"
								>
									Confirmed Members({{ confirmedMembers.length }})
								</label>
								<button
									type="button"
									class="tw-flex-shrink-0 tw-bg-white tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-dashed tw-border-gray-200 tw-text-gray-400 hover:tw-text-gray-500 hover:tw-border-gray-300 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-500"
									@click="showInviteMember = true"
									v-tooltip="'Invite Members'"
								>
									<span class="tw-sr-only">Add organization member</span>
									<svg
										class="tw-h-5 tw-w-5"
										x-description="Heroicon name: solid/plus"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
										fill="currentColor"
										aria-hidden="true"
									>
										<path
											fill-rule="evenodd"
											d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
											clip-rule="evenodd"
										/>
									</svg>
								</button>
							</div>
							<div class="tw-flex">
								<div class="tw-mb-3 tw-text-sm tw-text-gray-500 tw-w-full">
									Feel free to invite more members to join the organization.
								</div>
							</div>
							<div class="tw-flex tw-space-x-2">
								<ul
									role="list"
									class="tw-w-full tw-grid tw-grid-cols-1 tw-gap-6 lg:tw-grid-cols-2 2xl:tw-grid-cols-3"
								>
									<li
										v-for="thisMember in confirmedMembers"
										:key="thisMember.orgMemberId"
										class="tw-col-span-1 tw-bg-white tw-rounded-lg tw-shadow tw-divide-y tw-divide-gray-200"
									>
										<div class="tw-w-full tw-flex tw-items-center tw-justify-between tw-p-4 xl:tw-p-6 tw-space-x-3">
											<a
												:href="'/@'+thisMember.user.username"
												v-tooltip="thisMember.user.username"
											>
												<Avatar
													:email="thisMember.user.email"
													:px="40"
													:size="10"
												/>
											</a>
											<div class="tw-flex-1 tw-truncate">
												<div class="tw-flex tw-items-center tw-space-x-3">
													<h3 class="tw-text-gray-900 tw-text-sm tw-font-medium tw-truncate">
														{{ thisMember.user.fullName }}
													</h3>
												</div>
												<p class="tw-mt-0 tw-text-gray-500 tw-text-xs tw-truncate">
													<span class="tw-font-medium">Role:</span>
													<span class="tw-flex-shrink-0 tw-inline-block tw-px-2 tw-py-0.5 tw-text-green-800 tw-text-xs tw-font-medium tw-bg-green-100 tw-rounded-full">
														{{ thisMember.isOwner ? "Owner" : thisMember.role.role }}
													</span>
												</p>
												<p class="tw-mt-1 tw-text-gray-500 tw-text-xs tw-truncate">
													<span class="tw-font-medium">
														Member Since:
													</span>
													{{ thisMember.respondedDate | formatDate }}
												</p>
											</div>
											<svg
												v-if="!thisMember.isOwner"
												xmlns="http://www.w3.org/2000/svg"
												class="tw-h-6 tw-w-6 tw-text-gray-400 hover:tw-opacity-75 tw-cursor-pointer"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
												v-tooltip="'Remove Member'"
												@click="openRemovalDialog(thisMember.orgMemberId)"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
												/>
											</svg>
										</div>
									</li>
								</ul>
							</div>
						</div>

						<div
							v-if="pendingMembers.length"
							class="tw-mt-3"
						>
							<label
								for="first_name"
								class="tw-block tw-text-sm tw-font-medium tw-text-gray-700"
							>
								Pending Members({{ pendingMembers.length }})
							</label>
							<div class="tw-flex tw-space-x-2">
								<ul
									role="list"
									class="tw-w-full tw-grid tw-grid-cols-1 tw-gap-6 2xl:tw-grid-cols-3 lg:tw-grid-cols-2"
								>
									<li
										v-for="thisMember in pendingMembers"
										:key="thisMember.orgMemberId"
										class="tw-col-span-1 tw-bg-white tw-rounded-lg tw-shadow tw-divide-y tw-divide-gray-200"
									>
										<div class="tw-w-full tw-flex tw-items-center tw-justify-between tw-p-4 xl:tw-p-6 tw-space-x-3">
											<a
												:href="'/@'+thisMember.user.username"
												v-tooltip="thisMember.user.username"
											>
												<Avatar
													:email="thisMember.user.email"
													:px="40"
													:size="10"
												/>
											</a>
											<div class="tw-flex-1 tw-truncate">
												<div class="tw-flex tw-items-center tw-space-x-3">
													<h3 class="tw-text-gray-900 tw-text-sm tw-font-medium tw-truncate">
														{{ thisMember.user.fullName }}
													</h3>
												</div>
												<p class="tw-mt-0 tw-text-gray-500 tw-text-xs tw-truncate">
													<span class="tw-font-medium">Role:</span>
													<span class="tw-flex-shrink-0 tw-inline-block tw-px-2 tw-py-0.5 tw-text-green-800 tw-text-xs tw-font-medium tw-bg-green-100 tw-rounded-full">
														{{ thisMember.isOwner ? "Owner" : thisMember.role.role }}
													</span>
												</p>
												<p class="tw-mt-0 tw-text-gray-500 tw-text-xs tw-truncate">
													<span class="tw-font-medium">Invite Sent On:</span>
													{{ thisMember.createdDate | formatDate }}
												</p>
											</div>
											<svg
												v-if="!thisMember.isOwner && 'orgMemberId' in thisMember"
												xmlns="http://www.w3.org/2000/svg"
												class="tw-h-6 tw-w-6 tw-text-gray-400 hover:tw-opacity-75 tw-cursor-pointer"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
												v-tooltip="'Remove Member'"
												@click="openRemovalDialog(thisMember.orgMemberId)"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
												/>
											</svg>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import InviteMembers from "./InviteMembers";
import UpdatePaymentMethod from "./UpdatePaymentMethod";
import swal from "sweetalert2";
import moment from "moment";

export default {
	props : {
		member : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		},
		organization : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		},
		plan : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		},
		stripePublishableKey : {
			type     : String,
			required : true,
			default  : ""
		},
		subscriptionDetails : {
			type     : Object,
			required : true,
			default  : function() {
				return { content: { "current_period_end": new Date() } };
			}
		},
		cardDetails : {
			type     : Object,
			required : true,
			default  : function() {
				return {};
			}
		}
	},
	components : { InviteMembers, UpdatePaymentMethod },
	data() {
		return {
			menu                    : [ { label: "Billing" } ],
			showUpdatePaymentMethod : false,
			showInviteMember        : false,
			orgMembers              : this.organization.members,
			loading                 : false,
			error                   : "",
			notification            : {
				type    : "success",
				title   : "Success",
				message : "",
				show    : false
			}
		};
	},
	computed : {
		pendingMembers(){
			return this.orgMembers.filter( m => !m.isAccepted );
		},
		confirmedMembers(){
			return this.orgMembers.filter( m => m.isAccepted );
		},
		currentAmount() {
			return this.organization.isComplimentary || this.orgMembers.length < 2 ? 0 : this.plan.price * ( this.subscriptionDetails.content.quantity ); // Get actual number from stripe instead to make sure is right
		},
		nextBillDate() {
			if ( !this.organization.isComplimentary && this.subscriptionDetails?.content?.current_period_end ) {
				return moment( new Date( this.subscriptionDetails.content.current_period_end ) ).format( "ll" );
			}
			return "N/A";
		},
		canManageBilling(){
			return this.member.role.role == "Super Admin";
		}
	},
	methods : {
		openRemovalDialog( orgMemberId ) {
			var app = this;
			app.notification.show = false;
			swal.fire( {
				title              : "Are you sure?",
				text               : "You are about to remove this member from the organization. The member will be removed immediately and your new billing will be updated.",
				icon               : "warning",
				showCancelButton   : true,
				confirmButtonText  : "Yes",
				confirmButtonColor : "#e02424",
				reverseButtons     : true
			} ).then( ( result ) => {
				if ( result.isConfirmed ) {
					app.$http.delete(
						`/orgs/${app.organization.slug}/members/delete/${orgMemberId}`
					)
						.then( ( response ) => {
							app.loading = false;
							if ( !response.data.error ) {
								app.updateUI( orgMemberId );
								app.notification = {
									type    : "success",
									title   : "Success",
									message : response.data.data,
									show    : true
								};
							} else {
								console.log( response );
								app.notification = {
									type    : "error",
									title   : "Error",
									message : "Oops there was an error deleting the member. Please try again",
									show    : true
								};
							}
						} ).catch( error => {
							app.loading = false;
							app.errors.push( error.response.data.messages[0] );
						} );
				}
			} );
		},
		updateUI( orgMemberId ) {
			var app = this;
			app.orgMembers = app.orgMembers.filter(
				m => m.orgMemberId !== orgMemberId
			);
		},
		isEmpty( object ) {
			return Object.keys( object ).length === 0 && object.constructor === Object;
		}
	}
};
</script>