<template>
	<div class="tw-relative tw-bg-gray-50">
		<div class="lg:tw-relative">
			<div class="tw-mx-auto tw-max-w-7xl tw-w-full tw-pt-6 tw-text-center lg:tw-py-10 lg:tw-text-left">
				<div class="tw-flex tw-justify-between tw-items-center tw-py-6 md:tw-space-x-10">
					<div class="tw-px-4 tw-w-full lg:tw-w-1/2 sm:tw-px-8">
						<div class="text-center">
							<img
								src="/includes/images/ForgeBox150.png"
								class="tw-mx-auto tw-w-60"
							>
						</div>
						<p class="tw-text-4xl tw-tracking-tight tw-font-extrabold tw-text-gray-900 sm:tw-text-5xl md:tw-text-6xl lg:tw-text-5xl xl:tw-text-6xl tw-mt-3">
							<span class="tw-block tw-text-blue-600 xl:tw-inline">
								Modernize all things
							</span>
						</p>
						<p class="tw-mt-3 tw-max-w-md tw-mx-auto tw-text-lg tw-text-gray-500 sm:tw-text-xl md:tw-mt-5 md:tw-max-w-3xl">
							ColdFusion CFML development tools and packages that help you build modern web applications.
						</p>
						<div class="tw-mt-10 sm:tw-flex sm:tw-justify-center">
							<div class="tw-rounded-md tw-shadow">
								<a
									href="/about#editions"
									class="tw-w-full tw-flex tw-items-center tw-justify-center tw-px-8 tw-py-3 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-md tw-bg-blue-700 hover:tw-bg-blue-600 hover:tw-text-white tw-text-white md:tw-py-4 md:tw-text-lg md:tw-px-10"
								>
									See editions
								</a>
							</div>
							<div class="tw-mt-3 tw-rounded-md tw-shadow sm:tw-mt-0 sm:tw-ml-3">
								<a
									href="/signup"
									class="tw-w-full tw-flex tw-items-center tw-justify-center tw-px-8 tw-py-3 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-md tw-text-blue-600 tw-bg-white hover:tw-bg-gray-50 hover:tw-text-blue-500 md:tw-py-4 md:tw-text-lg md:tw-px-10"
								>
									Join for free
								</a>
							</div>
						</div>
					</div>
					<div class="tw-hidden md:tw-block">
						<img
							class="tw-w-90 tw-h-90"
							src="/includes/images/about/TopSection.png"
							alt=""
						>
					</div>
				</div>
			</div>
		</div>

		<div class="tw-bg-blue-600 tw-py-10">
			<div class="tw-flex tw-max-w-7xl tw-mx-auto lg:tw-py-16 tw-p-6 tw-space-x-8">
				<div class="tw-flex-1 tw-mx-auto tw-w-full tw-text-center lg:tw-text-left tw-text-white">
					<p class="tw-text-2xl tw-align-middle">
						<span class="tw-font-bold">FORGEBOX</span> is the package repository and software directory for BoxLang and ColdFusion (CFML). Just leverage CommandBox CLI so you can also contribute to this directory and build amazing reusable code.
					</p>
				</div>
				<div class="tw-flex-1 tw-hidden md:tw-block tw-mx-auto tw-w-full tw-text-center lg:tw-text-left tw-text-white">
					<img
						src="/includes/images/about/forgebox-6.gif"
						class="tw-rounded-lg"
					>
				</div>
			</div>
		</div>

		<div class="tw-bg-gray-50 tw-py-16 tw-p-6">
			<div class="tw-max-w-7xl tw-mx-auto">
				<p class="tw-block tw-text-blue-600 tw-text-4xl tw-font-extrabold tw-mb-5">
					Empowering Coldfusion CFML Apps
				</p>
				<div class="tw-relative tw-gap-6 tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-mt-3">
					<div class="tw-flex">
						<img
							class="tw-flex-1 tw-w-20"
							src="/includes/images/about/SoftwareDirectory.png"
							alt=""
						>
						<div class="tw-flex-1 tw-align-middle tw-grid tw-content-center">
							<p class="tw-font-bold tw-text-2xl">
								Software Directory
							</p>
							<p class="tw-flex-1 tw-text-lg tw-text-gray-500 tw-mt-3">
								Discover and find reusable modules for your web applications.
							</p>
						</div>
					</div>
					<div class="tw-flex">
						<img
							class="tw-flex-1 tw-w-20"
							src="/includes/images/about/Community.png"
							alt=""
						>
						<div class="tw-flex-1 tw-align-middle tw-grid tw-content-center">
							<p class="tw-font-bold tw-text-2xl">
								Community
							</p>
							<p class="tw-flex-1 tw-text-lg tw-text-gray-500 tw-mt-3">
								Share your work and help others make life easier.
							</p>
						</div>
					</div>
					<div class="tw-flex">
						<img
							class="tw-flex-1 tw-w-20"
							src="/includes/images/about/Collaborate.png"
							alt=""
						>
						<div class="tw-flex-1 tw-align-middle tw-grid tw-content-center">
							<p class="tw-font-bold tw-text-2xl">
								Collaborate
							</p>
							<p class="tw-flex-1 tw-text-lg tw-text-gray-500 tw-mt-3">
								Collaborate with others and create awesome modules.
							</p>
						</div>
					</div>
					<div class="tw-flex">
						<img
							class="tw-flex-1 tw-w-20"
							src="/includes/images/about/Modernize.png"
							alt=""
						>
						<div class="tw-flex-1 tw-align-middle tw-grid tw-content-center">
							<p class="tw-font-bold tw-text-2xl">
								Modernize
							</p>
							<p class="tw-flex-1 tw-text-lg tw-text-gray-500 tw-mt-3">
								Leverage modern package management to power your development process.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="tw-bg-blue-600 tw-py-10">
			<div class="tw-flex tw-max-w-7xl tw-mx-auto tw-space-x-8">
				<pricing
					:plan="plan"
					:is-about-page="true"
				/>
			</div>
		</div>

		<!-- Plan Comparison -->
		<div
			id="plan-comparison"
			class="tw-bg-gray-50 tw-py-16 tw-p-6"
		>
			<div class="tw-max-w-7xl tw-mx-auto">
				<p class="tw-block tw-text-blue-600 tw-text-4xl tw-font-extrabold tw-mb-5">
					Plan Comparison
				</p>
				<div class="tw-overflow-x-auto tw-w-full">
					<table class="tw-mx-auto tw-w-full tw-whitespace-nowrap tw-rounded-lg tw-bg-white tw-divide-y tw-divide-gray-100 tw-shadow tw-overflow-x-auto">
						<thead>
							<tr class="tw-border-b hover:tw-bg-gray-50">
								<th
									class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider"
									scope="col"
								>
									FEATURES
								</th> <th
									class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider tw-text-center"
									scope="col"
								>
									COMMUNITY
								</th> <th
									class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider tw-text-center"
									scope="col"
								>
									PRO
								</th> <th
									class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider tw-text-center"
									scope="col"
								>
									BUSINESS
								</th> <th
									class="tw-px-6 tw-py-3 tw-border-b tw-border-gray-200 tw-bg-gray-100 tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider tw-text-center"
									scope="col"
								>
									ENTERPRISE
								</th>
							</tr>
						</thead>
						<tbody>
							<tr class="tw-border-b hover:tw-bg-gray-50">
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<p class="tw-font-bold tw-text-left">
										Unlimited Public Packages
									</p>
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fas fa-check-circle" />
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fas fa-check-circle" />
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fas fa-check-circle" />
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fas fa-check-circle" />
								</td>
							</tr>
							<tr class="tw-border-b hover:tw-bg-gray-50">
								<td
									class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center"
									scope="row"
								>
									<p class="tw-font-bold tw-text-left">
										Unlimited Public
									</p>
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fas fa-check-circle" />
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fas fa-check-circle" />
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fas fa-check-circle" />
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fas fa-check-circle" />
								</td>
							</tr>
							<tr class="tw-border-b hover:tw-bg-gray-50">
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<p class="tw-font-bold tw-text-left">
										Collaborators
									</p>
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fas fa-check-circle" />
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fas fa-check-circle" />
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fas fa-check-circle" />
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fas fa-check-circle" />
								</td>
							</tr>
							<tr class="tw-border-b hover:tw-bg-gray-50">
								<td
									class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center"
									scope="row"
								>
									<p class="tw-font-bold tw-text-left">
										Public Package based permissions
									</p>
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fa fa-ban" />
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fas fa-check-circle" />
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fas fa-check-circle" />
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fas fa-check-circle" />
								</td>
							</tr>
							<tr class="tw-border-b hover:tw-bg-gray-50">
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<p class="tw-font-bold tw-text-left">
										Private Packages
									</p>
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<span>1</span>
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<span>10</span>
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<span>Unlimited</span>
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<span>Unlimited</span>
								</td>
							</tr>
							<tr class="tw-border-b hover:tw-bg-gray-50">
								<td
									class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center"
									scope="row"
								>
									<p class="tw-font-bold tw-text-left">
										Private Collaborators
									</p>
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fa fa-ban" />
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fas fa-check-circle" />
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fas fa-check-circle" />
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fas fa-check-circle" />
								</td>
							</tr>
							<tr class="tw-border-b hover:tw-bg-gray-50">
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<p class="tw-font-bold tw-text-left">
										Private Package based permissions
									</p>
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fa fa-ban" />
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fa fa-ban" />
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fas fa-check-circle" />
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fas fa-check-circle" />
								</td>
							</tr>
							<tr class="tw-border-b hover:tw-bg-gray-50">
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<p class="tw-font-bold tw-text-left">
										Activity Logs
									</p>
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<span>7 days</span>
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<span>90 days</span>
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<span>180 days</span>
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<span>180 days</span>
								</td>
							</tr>
							<tr class="tw-border-b hover:tw-bg-gray-50">
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<p class="tw-font-bold tw-text-left">
										Package Subscriptions
									</p>
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<span>3</span>
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<span>Unlimited</span>
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<span>Unlimited</span>
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<span>Unlimited</span>
								</td>
							</tr>
							<tr class="tw-border-b hover:tw-bg-gray-50">
								<td
									class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center"
									scope="row"
								>
									<p class="tw-font-bold tw-text-left">
										Unlimited Organizations
									</p>
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fa fa-ban" />
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fa fa-ban" />
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fas fa-check-circle" />
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fas fa-check-circle" />
								</td>
							</tr>
							<tr class="tw-border-b hover:tw-bg-gray-50">
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<p class="tw-font-bold tw-text-left">
										Organization member role-based security
									</p>
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fa fa-ban" />
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fa fa-ban" />
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fas fa-check-circle" />
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fas fa-check-circle" />
								</td>
							</tr>
							<tr class="tw-border-b hover:tw-bg-gray-50">
								<td
									class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center"
									scope="row"
								>
									<p class="tw-font-bold tw-text-left">
										Unlimited Teams
									</p>
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fa fa-ban" />
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fa fa-ban" />
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fas fa-check-circle" />
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fas fa-check-circle" />
								</td>
							</tr>
							<tr class="tw-border-b hover:tw-bg-gray-50">
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<p class="tw-font-bold tw-text-left">
										Team-based package security
									</p>
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fa fa-ban" />
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fa fa-ban" />
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fas fa-check-circle" />
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<i class="fas fa-check-circle" />
								</td>
							</tr>
							<tr class="tw-border-b hover:tw-bg-gray-50">
								<td
									class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center"
									scope="row"
								>
									<p class="tw-font-bold tw-text-left">
										Storage
									</p>
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<span>10GB</span>
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<span>50GB</span>
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<span>100GB</span>
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<span>250GB</span>
								</td>
							</tr>
							<tr class="tw-border-b hover:tw-bg-gray-50">
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<p class="tw-font-bold tw-text-left">
										Support
									</p>
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<span>Community</span>
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<span>Email + Slack</span>
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<span>Slack + Helpdesk</span>
								</td>
								<td class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center">
									<span>Slack + Helpdesk</span>
								</td>
							</tr>
							<tr class="tw-border-b tw-bg-gray-100">
								<td
									class="tw-px-6 tw-py-3 tw-whitespace-nowrap tw-text-sm tw-text-gray-500 tw-text-center"
									scope="row"
								>
									<p class="tw-font-bold tw-text-left">
										Price
									</p>
								</td>
								<td class="d-grid">
									<p class="tw-font-bold tw-text-center tw-mt-3">
										FREE
									</p>
									<p
										v-if="plan == ''"
										class="tw-text-center"
									>
										<a
											href="/signup"
											class="tw-inline-block tw-my-2 tw-bg-blue-700 hover:tw-bg-blue-600 tw-border-transparent tw-text-white tw-px-4 tw-py-3 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md hover:tw-text-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-700"
										>
											JOIN FOR FREE
										</a>
									</p>
								</td>
								<td class="d-grid">
									<p class="tw-font-bold tw-text-center tw-mt-3">
										$4.99/month
									</p>
									<p class="tw-text-center">
										<a
											:href="plan == 'pro' ? '/myaccount/settings/billing' : '/subscribe/pro'"
											class="tw-inline-block tw-my-2 tw-bg-blue-700 hover:tw-bg-blue-600 tw-border-transparent tw-text-white tw-px-4 tw-py-3 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md hover:tw-text-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-700"
										>
											{{ plan == 'pro' ? 'VIEW YOUR PLAN' : 'SUBSCRIBE NOW' }}
										</a>
									</p>
								</td>
								<td class="d-grid">
									<p class="tw-font-bold tw-text-center tw-mt-3">
										$6.99/month/user
									</p>
									<p class="tw-text-center">
										<a
											href="/myaccount/organizations"
											class="tw-inline-block tw-my-2 tw-bg-blue-700 hover:tw-bg-blue-600 tw-border-transparent tw-text-white tw-px-4 tw-py-3 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md hover:tw-text-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-700"
										>
											START NOW
										</a>
									</p>
								</td>
								<td class="d-grid">
									<p class="tw-font-bold tw-text-center tw-mt-3">
										$199/month
									</p>
									<p class="tw-text-center">
										<a
											href="https://www.ortussolutions.com/contact?subject=FORGEBOX%20ENTERPRISE"
											class="tw-inline-block tw-my-2 tw-bg-blue-700 hover:tw-bg-blue-600 tw-border-transparent tw-text-white tw-px-4 tw-py-3 tw-shadow-sm tw-text-sm tw-font-medium tw-rounded-md hover:tw-text-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-blue-700"
										>
											CONTACT SALES
										</a>
									</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

		<!-- Support -->
		<div class="tw-bg-blue-600 tw-py-10">
			<div class="tw-max-w-7xl tw-mx-auto">
				<p class="tw-block tw-text-white tw-text-center tw-text-3xl tw-font-extrabold tw-mb-3">
					Still have questions?
				</p>
				<p class="tw-block tw-text-white tw-text-center tw-text-3xl tw-font-extrabold tw-mb-5">
					Get in touch
				</p>
				<div class="tw-flex tw-mx-auto">
					<a
						href="/support"
						class="tw-w-64 tw-mt-6 tw-flex tw-mx-auto tw-items-center tw-justify-center tw-px-8 tw-py-3 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-md tw-text-blue-600 tw-bg-white hover:tw-bg-gray-50 hover:tw-text-blue-500"
					>
						Contact Support
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Pricing from "./Pricing.vue";

export default {
	props : {
		plan : {
			type     : String,
			required : false,
			default  : ""
		}
	},
	components : { Pricing }
};

</script>
